import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Select, Space } from 'antd';
import { cn } from '../../config/cn';
import { PropertyEditorProps, PropertyType, SchemaProperty } from '../../types/custom-tool.types';

const PropertyEditor = ({ property, onChange, level = 0 }: PropertyEditorProps) => {
	const handleTypeChange = (type: PropertyType) => {
		let newValue: string | number | boolean | string[] | Record<string, SchemaProperty> = '';
		switch (type) {
			case 'string':
				newValue = '';
				break;
			case 'number':
				newValue = 0;
				break;
			case 'boolean':
				newValue = false;
				break;
			case 'array':
				newValue = [];
				break;
			case 'object':
				newValue = {};
				break;
		}
		onChange({ ...property, type, value: newValue });
	};

	const handleValueChange = (value: string | number | boolean) => {
		onChange({ ...property, value });
	};

	const addArrayItem = () => {
		if (property.type === 'array') {
			const newValue = [...(property.value as string[]), ''];
			onChange({ ...property, value: newValue });
		}
	};

	const removeArrayItem = (index: number) => {
		if (property.type === 'array') {
			const newValue = [...(property.value as string[])];
			newValue.splice(index, 1);
			onChange({ ...property, value: newValue });
		}
	};

	const updateArrayItem = (index: number, newValue: string) => {
		if (property.type === 'array') {
			const values = [...(property.value as string[])];
			values[index] = newValue;
			onChange({ ...property, value: values });
		}
	};

	const addObjectProperty = () => {
		if (property.type === 'object') {
			const newValue = {
				...(property.value as Record<string, SchemaProperty>),
				[`property${Object.keys(property.value as object).length + 1}`]: { key: '', type: 'string' as PropertyType, value: '' },
			};
			onChange({ ...property, value: newValue });
		}
	};

	const removeObjectProperty = (key: string) => {
		if (property.type === 'object') {
			const newValue = { ...(property.value as Record<string, SchemaProperty>) };
			delete newValue[key];
			onChange({ ...property, value: newValue });
		}
	};

	const updateObjectProperty = (key: string, updatedProperty: SchemaProperty) => {
		if (property.type === 'object') {
			const newValue = { ...(property.value as Record<string, SchemaProperty>) };
			newValue[key] = updatedProperty;
			onChange({ ...property, value: newValue });
		}
	};

	return (
		<div className={cn(level > 0 ? 'ml-4 mt-2 border p-2 rounded-lg' : '')}>
			<Space
				direction="vertical"
				style={{ width: '100%' }}
			>
				<Space>
					<Input
						placeholder="Property name"
						value={property.key}
						onChange={(e) => onChange({ ...property, key: e.target.value })}
						style={{ width: 150 }}
					/>

					<Select
						value={property.type}
						onChange={handleTypeChange}
						style={{ width: 120 }}
						options={[
							{ label: 'String', value: 'string' },
							{ label: 'Number', value: 'number' },
							{ label: 'Boolean', value: 'boolean' },
							{ label: 'Array', value: 'array' },
							{ label: 'Object', value: 'object' },
						]}
					/>
				</Space>

				{property.type === 'string' && (
					<Input
						placeholder="String value"
						value={property.value as string}
						onChange={(e) => handleValueChange(e.target.value)}
					/>
				)}

				{property.type === 'number' && (
					<Input
						type="number"
						placeholder="Number value"
						value={property.value as number}
						onChange={(e) => handleValueChange(Number(e.target.value))}
					/>
				)}

				{property.type === 'boolean' && (
					<Select
						value={property.value as boolean}
						onChange={handleValueChange}
						style={{ width: '100%' }}
						options={[
							{ label: 'True', value: true },
							{ label: 'False', value: false },
						]}
					/>
				)}

				{property.type === 'array' && (
					<div className="border-l-2 pl-2">
						{(property.value as string[]).map((item, index) => (
							<div
								key={index}
								className="mb-2 flex items-center gap-2"
							>
								<Input
									placeholder="Enum value"
									value={item}
									onChange={(e) => updateArrayItem(index, e.target.value)}
									style={{ width: '100%' }}
								/>
								<Button
									type="text"
									danger
									icon={<DeleteOutlined />}
									onClick={() => removeArrayItem(index)}
								/>
							</div>
						))}
						<Button
							type="dashed"
							icon={<PlusOutlined />}
							onClick={addArrayItem}
							className="mt-2"
						>
							Add Item
						</Button>
					</div>
				)}

				{property.type === 'object' && (
					<div className="border-l-2 pl-2">
						{Object.entries(property.value as Record<string, SchemaProperty>).map(([key, prop]) => (
							<PropertyEditor
								key={key}
								property={prop}
								onChange={(updated) => updateObjectProperty(key, updated)}
								onDelete={() => removeObjectProperty(key)}
								level={level + 1}
							/>
						))}
						<Button
							type="dashed"
							icon={<PlusOutlined />}
							onClick={addObjectProperty}
							className="mt-2"
						>
							Add Property
						</Button>
					</div>
				)}
			</Space>
		</div>
	);
};

export default PropertyEditor;
