import { useQuery } from '@tanstack/react-query';
import axios from '../config/axios';

export type Behavior = {
	behavior_id: string;
	name: string;
};

const useBehaviorQuery = (debouncedBehaviorSearchText: string) => {
	return useQuery<Behavior[]>({
		queryKey: ['behaviors', debouncedBehaviorSearchText],
		queryFn: async () => {
			const { data } = await axios.get('/commons/behaviors', {
				withCredentials: true,
				params: {
					name: debouncedBehaviorSearchText || null,
				},
			});
			return data.results;
		},
	});
};

export default useBehaviorQuery;
