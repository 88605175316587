import Icon, { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, Drawer, Input, message, Select } from 'antd';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { BsChevronDown } from 'react-icons/bs';
import { IoIosLink, IoMdAdd } from 'react-icons/io';
import { PiMagicWandLight } from 'react-icons/pi';
import { RiToolsFill } from 'react-icons/ri';
import { RxLapTimer, RxMinusCircled } from 'react-icons/rx';
import { VscJson } from 'react-icons/vsc';
import { cn } from '../../config/cn';
import { CreateCustomToolDrawerProps, CustomToolFormState } from '../../types/custom-tool.types';
import { formatDataForApi, getTitleSubtitle } from './custom-tool-utils';
import PropertyEditor from './property-editor';

const StepDot = ({ active }: { active: boolean }) => (
	<div className={cn('size-2 rounded-full bg-blue-400 transition-all duration-300', { 'w-8 rounded-lg': active })} />
);

const CreateCustomToolDrawer = (props: CreateCustomToolDrawerProps) => {
	const { open, close } = props;
	const [step, setStep] = useState(1);

	// Consolidated form state
	const [formState, setFormState] = useState<CustomToolFormState>({
		serverUrl: '',
		timeoutSeconds: '20',
		headers: [{ key: '', value: '' }],
		schemaProperties: [{ key: '', type: 'string', value: '' }],
		requiredFields: [],
		toolName: '',
		toolDescription: '',
		startMessage: '',
		delayedMessage: '',
		successMessage: '',
		errorMessage: '',
	});

	// Loading state for API calls
	const [isSubmitting, setIsSubmitting] = useState(false);

	const { headers, schemaProperties, requiredFields } = formState;

	// Update the form state with a partial update
	const updateFormState = (update: Partial<CustomToolFormState>) => {
		setFormState((prev) => ({ ...prev, ...update }));
	};

	const addHeaderRow = () => {
		if (formState.headers.length < 5) {
			updateFormState({
				headers: [...formState.headers, { key: '', value: '' }],
			});
		}
	};

	const removeHeaderRow = (index: number) => {
		const newHeaders = [...formState.headers];
		newHeaders.splice(index, 1);
		updateFormState({ headers: newHeaders });
	};

	const handleHeaderChange = (index: number, field: 'key' | 'value', value: string) => {
		const newHeaders = [...formState.headers];
		newHeaders[index][field] = value;
		updateFormState({ headers: newHeaders });
	};

	const addProperty = () => {
		updateFormState({
			schemaProperties: [...formState.schemaProperties, { key: '', type: 'string', value: '' }],
		});
	};

	const removeProperty = (index: number) => {
		const propertyToRemove = formState.schemaProperties[index];
		const newProperties = [...formState.schemaProperties];
		newProperties.splice(index, 1);

		// Also remove from required fields
		let newRequiredFields = [...formState.requiredFields];
		if (propertyToRemove.key && newRequiredFields.includes(propertyToRemove.key)) {
			newRequiredFields = newRequiredFields.filter((field) => field !== propertyToRemove.key);
		}

		updateFormState({
			schemaProperties: newProperties,
			requiredFields: newRequiredFields,
		});
	};

	// Submit the form
	const handleSubmit = async () => {
		try {
			setIsSubmitting(true);
			const formattedData = formatDataForApi(formState);

			console.log('Submitting data:', formattedData);

			toast.success('Feature not implemented yet');
		} catch (error) {
			console.error('Error creating custom tool:', error);
			message.error('Failed to create custom tool');
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<Drawer
			title={
				<div className="flex items-center gap-2">
					<RiToolsFill size={24} />
					<div className="text-lg font-medium">Create Custom Tool</div>
				</div>
			}
			open={open}
			onClose={() => {
				close();
			}}
			placement="left"
			width={600}
			styles={{
				content: { borderRadius: 10 },
				mask: { backdropFilter: 'blur(5px)' },
				body: { padding: 0 },
				wrapper: { margin: 15 },
				footer: { padding: 15 },
			}}
			closeIcon={false}
			footer={
				<div className="flex justify-end gap-2">
					<Button
						type="dashed"
						size="large"
						onClick={close}
						disabled={isSubmitting}
					>
						Close
					</Button>

					<Button
						size="large"
						disabled={step === 1 || isSubmitting}
						onClick={() => {
							if (step === 1) return;
							setStep(step - 1);
						}}
					>
						Previous
					</Button>

					{step === 4 ? (
						<Button
							type="primary"
							size="large"
							onClick={handleSubmit}
							loading={isSubmitting}
						>
							Create
						</Button>
					) : (
						<Button
							type="primary"
							size="large"
							onClick={() => {
								// TODO: Validate current step before proceeding

								setStep(step + 1);
							}}
						>
							Next
						</Button>
					)}
				</div>
			}
		>
			<div className="p-4">
				<h2 className="text-lg font-medium">{getTitleSubtitle(step).title}</h2>
				<p className="text-base text-gray-500">{getTitleSubtitle(step).subtitle}</p>

				<div className="pt-2" />

				<div className="flex gap-1.5">
					{Array.from({ length: 4 }).map((_, index) => (
						<StepDot
							key={index}
							active={index + 1 === step}
						/>
					))}
				</div>
			</div>

			{step === 1 && (
				<>
					<div className="px-4 pb-4">
						<div className="grid grid-cols-2 gap-4">
							<div
								className="flex flex-col gap-1 rounded-lg border bg-gray-100 p-4 dark:border-dark-border dark:bg-dark-border"
								role="button"
							>
								<PiMagicWandLight size={28} />
								<div className="text-lg font-semibold">Custom Tool</div>
								<div className="text-sm font-medium text-gray-400">
									Create a custom Function and connect it to any backend implementation.
								</div>
							</div>
						</div>

						<div className="pt-2" />

						<div className="text-sm text-gray-400">Create a custom Function and connect it to any backend implementation.</div>
					</div>

					<hr className="dark:border-dark-border" />

					<div className="p-4">
						<div className="rounded-lg border p-4 shadow-sm dark:border-dark-border">
							<div className="flex flex-col gap-2">
								<div className="flex items-center gap-2">
									<IoIosLink size={18} />
									<div className="text-base font-semibold">Server URL</div>
								</div>
								<Input
									placeholder="Server URL for your custom POST endpoint"
									size="large"
									value={formState.serverUrl}
									onChange={(e) => updateFormState({ serverUrl: e.target.value })}
								/>
							</div>

							<div className="pt-4" />

							<div className="flex flex-col gap-2">
								<div className="flex items-center gap-2">
									<RxLapTimer size={18} />
									<div className="text-base font-semibold">Timeout (Seconds)</div>
								</div>

								<div className="text-sm text-gray-400">
									This is the number of seconds the tool will wait for a response from the server.
								</div>

								<Input
									placeholder="20"
									size="large"
									value={formState.timeoutSeconds}
									onChange={(e) => updateFormState({ timeoutSeconds: e.target.value })}
								/>
							</div>

							<div className="pt-4" />

							<div className="flex flex-col gap-1">
								<div className="flex items-center gap-2">
									<VscJson size={18} />
									<div className="text-base font-semibold">Headers</div>
								</div>

								<div className="text-sm text-gray-400">
									These are the custom headers to include in the request sent to your server. Each key-value pair represents a
									header name and its value. You can add up to 5 headers.
								</div>

								<div className="">
									<div className="mt-2 flex flex-col gap-3">
										<div className="flex items-center gap-2 px-2">
											<div className="flex-1 font-medium text-gray-600">Key</div>
											<div className="flex-1 font-medium text-gray-600">Value</div>
											{headers.length > 1 && <div className="w-4"></div>}
										</div>

										{headers.map((header, index) => (
											<div
												key={index}
												className="flex items-center gap-3"
											>
												<Input
													placeholder="Key"
													value={header.key}
													size="large"
													onChange={(e) => handleHeaderChange(index, 'key', e.target.value)}
												/>
												<Input
													placeholder="Value"
													value={header.value}
													size="large"
													onChange={(e) => handleHeaderChange(index, 'value', e.target.value)}
												/>
												{headers.length > 1 && (
													<div
														className="flex items-center justify-center rounded-md p-1"
														onClick={() => removeHeaderRow(index)}
														role="button"
													>
														<RxMinusCircled size={18} />
													</div>
												)}
											</div>
										))}

										{headers.length < 5 && (
											<Button
												type="dashed"
												icon={<IoMdAdd />}
												onClick={addHeaderRow}
												className="mt-2 w-fit"
												size="large"
											>
												Add Header
											</Button>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}

			{step === 2 && (
				<div className="px-4 pb-4">
					<div className="flex flex-col gap-4">
						<div className="flex items-center justify-between">
							<div className="text-base font-semibold">Schema Properties</div>
							<Button
								type="primary"
								icon={<PlusOutlined />}
								onClick={addProperty}
							>
								Add Property
							</Button>
						</div>

						{schemaProperties.map((property, index) => (
							<Collapse
								key={index}
								defaultActiveKey={[index]}
								expandIconPosition="end"
								size="small"
								expandIcon={({ isActive }) => (
									<Icon
										component={BsChevronDown}
										rotate={isActive ? 180 : 0}
										style={{ fontSize: 14 }}
									/>
								)}
								items={[
									{
										key: index,
										label: (
											<div className="flex w-full items-center justify-between">
												<span>{property.key || `Property ${index + 1}`}</span>
												{schemaProperties.length > 1 && (
													<Button
														icon={<DeleteOutlined />}
														onClick={(e) => {
															e.stopPropagation();
															removeProperty(index);
														}}
														danger
														size="small"
													/>
												)}
											</div>
										),
										children: (
											<PropertyEditor
												property={property}
												onChange={(updated) => {
													const newProperties = [...schemaProperties];
													newProperties[index] = updated;
													updateFormState({ schemaProperties: newProperties });
												}}
												onDelete={schemaProperties.length > 1 ? () => removeProperty(index) : undefined}
											/>
										),
									},
								]}
							/>
						))}

						<div>
							<div className="text-base font-semibold">Required Fields</div>
							<div className="mb-3 text-sm text-gray-500">Select which properties should be required in the schema</div>
							<Select
								mode="multiple"
								placeholder="Select required fields"
								style={{ width: '100%' }}
								value={requiredFields}
								size="large"
								onChange={(newRequiredFields) => updateFormState({ requiredFields: newRequiredFields })}
								options={schemaProperties
									.filter((prop) => prop.key.trim() !== '')
									.map((prop) => ({ label: prop.key, value: prop.key }))}
							/>
						</div>
					</div>
				</div>
			)}

			{step === 3 && (
				<div className="px-4">
					<div className="flex flex-col gap-2">
						<div className="text-base font-semibold">Name</div>
						<Input
							placeholder="What is your tool name? (e.g. get_user_info, get_user_email, lookup_address...)"
							size="large"
							value={formState.toolName}
							onChange={(e) => updateFormState({ toolName: e.target.value })}
						/>
					</div>

					<div className="pt-4">
						<div className="flex flex-col gap-2">
							<div className="text-base font-semibold">Description</div>
							<Input.TextArea
								placeholder="Describe the Tool, its purpose, its use, etc."
								size="large"
								autoSize={{
									minRows: 6,
									maxRows: 18,
								}}
								value={formState.toolDescription}
								onChange={(e) => updateFormState({ toolDescription: e.target.value })}
							/>
						</div>
					</div>
				</div>
			)}

			{step === 4 && (
				<div className="px-4">
					<div className="flex flex-col gap-2">
						<div className="text-base font-semibold">Start Message</div>
						<Input
							placeholder="message to show user when the tool is running"
							size="large"
							value={formState.startMessage}
							onChange={(e) => updateFormState({ startMessage: e.target.value })}
						/>
					</div>

					<div className="pt-4">
						<div className="flex flex-col gap-2">
							<div className="text-base font-semibold">Delayed Response Message</div>
							<Input
								placeholder="message to show user when the tool is delayed"
								size="large"
								value={formState.delayedMessage}
								onChange={(e) => updateFormState({ delayedMessage: e.target.value })}
							/>
						</div>
					</div>

					<div className="pt-4">
						<div className="flex flex-col gap-2">
							<div className="text-base font-semibold">Success Message</div>
							<Input
								placeholder="message to show user when the tool is successful"
								size="large"
								value={formState.successMessage}
								onChange={(e) => updateFormState({ successMessage: e.target.value })}
							/>
						</div>
					</div>

					<div className="pt-4">
						<div className="flex flex-col gap-2">
							<div className="text-base font-semibold">Error Message</div>
							<Input
								placeholder="message to show user when the tool execution fails"
								size="large"
								value={formState.errorMessage}
								onChange={(e) => updateFormState({ errorMessage: e.target.value })}
							/>
						</div>
					</div>
				</div>
			)}
		</Drawer>
	);
};

export default CreateCustomToolDrawer;
