import { Modal } from 'antd';
import { AxiosError } from 'axios';

export const handleAPIError = (error: Error) => {
	if (error instanceof AxiosError && error.response) {
		Modal.error({
			title: 'Error',
			content: error.response.data.msg,
			centered: true,
		});
	} else {
		Modal.error({
			title: 'Error',
			content: error.message,
			centered: true,
		});
	}
};

// convert seconds to proper format including hours
export const formatSeconds = (seconds: number): string => {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const remainingSeconds = seconds % 60;

	let result = '';

	if (hours > 0) {
		result += `${hours} hr `;
	}
	if (minutes > 0 || hours > 0) {
		// Include minutes if there are hours, even if minutes are 0
		result += `${minutes} min `;
	}
	result += `${remainingSeconds} sec`;

	return result.trim();
};

// format number to include commas
export const formatNumber = (num: number | string): string => {
	return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const compareCallPercentages = (currentMonthData: Record<string, number>, lastMonthData: Record<string, number>) => {
	const keys = ['total_calls_made', 'total_incoming_calls', 'total_outgoing_calls', 'total_seconds'];
	const results = {} as Record<string, { percentageChange: number; direction: 'up' | 'down' }>;

	keys.forEach((key) => {
		const lastMonthValue = lastMonthData[key];
		const currentMonthValue = currentMonthData[key];
		let percentageChange: number;
		let direction: 'up' | 'down';

		if (lastMonthValue === 0) {
			percentageChange = currentMonthValue > 0 ? 100 : 0;
			direction = currentMonthValue > 0 ? 'up' : 'down';
		} else {
			percentageChange = Math.round(Math.abs(((currentMonthValue - lastMonthValue) / lastMonthValue) * 100));
			direction = currentMonthValue > lastMonthValue ? 'up' : 'down';
		}

		results[key] = { percentageChange, direction };
	});

	return results;
};

export const compareChatPercentages = (currentMonthData: Record<string, number>, lastMonthData: Record<string, number>) => {
	const keys = ['total_messages', 'total_messages_sent', 'total_messages_received', 'total_chat_sessions'];
	const results = {} as Record<string, { percentageChange: number; direction: 'up' | 'down' }>;

	keys.forEach((key) => {
		const currentMonthValue = currentMonthData[key];
		const lastMonthCalculatedValue = lastMonthData[key];
		let direction: 'up' | 'down';
		let percentageChange: number;

		if (lastMonthCalculatedValue === 0) {
			percentageChange = currentMonthValue > 0 ? 100 : 0;
			direction = currentMonthValue > 0 ? 'up' : 'down';
		} else {
			percentageChange = Math.round(Math.abs(((currentMonthValue - lastMonthCalculatedValue) / lastMonthCalculatedValue) * 100));
			direction = currentMonthValue > lastMonthCalculatedValue ? 'up' : 'down';
		}

		results[key] = { percentageChange, direction };
	});

	return results;
};
