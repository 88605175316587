import { createContext, Dispatch, SetStateAction, useContext } from 'react';

interface ContextProps {
	isCollapsed: boolean;
	setIsCollapsed: Dispatch<SetStateAction<boolean>>;
}

export const SidebarContext = createContext<ContextProps>({
	isCollapsed: false,
	setIsCollapsed: () => false,
});

export const useSidebarContext = () => {
	const context = useContext(SidebarContext);

	if (!context) {
		throw new Error('useSidebar must be used within a SidebarProvider');
	}

	const { isCollapsed, setIsCollapsed } = context;

	return { isCollapsed, setIsCollapsed } as const;
};
