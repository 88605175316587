import { Button, Card, Divider, message, Tag } from 'antd';
import { FC, Fragment, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { HiMiniSquaresPlus } from 'react-icons/hi2';
import { MdCheck, MdModeEditOutline } from 'react-icons/md';
import axios from '../../config/axios';
import { cn } from '../../config/cn';
import { useConnectedAppsQuery } from '../../hooks/use-connected-apps-query';
import { useRuthConnectStore } from '../../store/ruth-connect';
import CreateIntegrationModal from './create-integration-modal';
import { App } from './ruth-connect-data';
import { Integration } from './ruth-connect-types';
interface RuthConnectCardProps {
	item: App;
	connectedApps: Integration[];
}

const RuthConnectCard: FC<RuthConnectCardProps> = ({ item, connectedApps }) => {
	const connected = connectedApps && connectedApps.some((app) => app.app_name === item.app_name);
	const connectedApp = connectedApps && connectedApps.find((app) => app.app_name === item.app_name);
	const { setOpenModalId } = useRuthConnectStore();
	const [deleteLoading, setDeleteLoading] = useState(false);

	const { refetch } = useConnectedAppsQuery();

	const handleDeleteIntegration = async () => {
		if (connectedApp) {
			setDeleteLoading(true);
			await axios.delete(`/ruth-connect/integrations/${connectedApp.integration_id}`, { withCredentials: true });
			setDeleteLoading(false);
			message.success('Integration deleted successfully');
			refetch();
		}
	};

	return (
		<Fragment>
			<Card
				styles={{ body: { padding: 20, height: '100%' } }}
				className="h-full"
			>
				<div className="flex h-full flex-col gap-2">
					<div className={'flex items-start justify-between'}>
						<div className="flex items-center gap-3">
							<div className={'flex h-12 w-12 items-center justify-center rounded-xl bg-gray-200'}>
								<img
									src={item.image}
									alt={item.title}
									className={cn('h-7', {
										'h-4': ['cal.com'].includes(item.app_name),
									})}
								/>
							</div>
							<div>
								<p className="font-medium text-black-3">{item.vendor}</p>
								<h3 className="text-base font-semibold text-black-7 dark:text-white">{item.title}</h3>
							</div>
						</div>

						{connected && (
							<div className="flex items-center gap-4">
								<Button
									className="p-2"
									title="Edit Integration"
									onClick={() => {
										setOpenModalId(item.uuid);
									}}
								>
									<MdModeEditOutline size={20} />
								</Button>

								<Button
									danger
									title="Delete Integration"
									onClick={handleDeleteIntegration}
									icon={<AiOutlineDelete size={18} />}
									loading={deleteLoading}
									disabled={deleteLoading}
								/>
							</div>
						)}
					</div>

					<div className="pt-2" />

					<p className="font-medium text-black-3">{item.content}</p>

					<div className="mt-auto">
						<Divider className={'my-3'} />
						{connected ? (
							<Button
								type="primary"
								icon={<MdCheck size={18} />}
								shape="round"
								className="h-9 w-36 !bg-green-600 font-medium"
							>
								Connected
							</Button>
						) : (
							<>
								{['calendly', 'cal.com'].includes(item.app_name) ? (
									<Button
										type="primary"
										icon={<HiMiniSquaresPlus size={18} />}
										shape="round"
										className="h-9 w-36 font-medium"
										onClick={() => {
											setOpenModalId(item.uuid);
										}}
									>
										Connect
									</Button>
								) : (
									<Tag color="#f50">Coming Soon</Tag>
								)}
							</>
						)}
					</div>
				</div>
			</Card>

			{/* connect and update modal */}
			<CreateIntegrationModal item={item} />
		</Fragment>
	);
};

export default RuthConnectCard;
