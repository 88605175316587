import { Carousel } from 'antd';
import { Fragment } from 'react';
import { cn } from '../../config/cn';
import { useSidebarContext } from '../../context/sidebar.context';

type SliderData = {
	image: string;
	title: string;
	onClick: () => void;
};

const sliderData: SliderData[] = [
	{
		image: '/images/ruth-connect/slider-image-one.png',
		title: 'Increase HubSpot form conversion with instant, account-matched scheduling',
		onClick: () => {
			console.log('clicked');
		},
	},
	{
		image: '/images/ruth-connect/slider-image-two.png',
		title: 'Quick, easy scheduling in Gong for better and faster lead conversion',
		onClick: () => {
			console.log('clicked');
		},
	},
	{
		image: '/images/ruth-connect/slider-image-three.png',
		title: 'Route leads to the right team member via real-time Salesforce lookup',
		onClick: () => {
			console.log('clicked');
		},
	},
	{
		image: '/images/ruth-connect/slider-image-four.png',
		title: 'Boost Market form conversion with instant and qualified booking',
		onClick: () => {
			console.log('clicked');
		},
	},
];

const RuthConnectSlider = () => {
	const { isCollapsed } = useSidebarContext();

	return (
		<Fragment>
			<div
				className={cn('w-[calc(100dvw-21.5rem)] max-[1160px]:hidden', {
					'w-[calc(100dvw-9.5rem)]': isCollapsed,
				})}
			>
				<Carousel
					className="slider variable-width"
					dots
					variableWidth
					draggable
				>
					{sliderData.map((item: SliderData, index: number) => (
						<div
							style={{ width: 850 }}
							className="h-60 overflow-hidden rounded-lg border dark:border-[#353440]"
							key={index}
						>
							<div className="grid grid-cols-2">
								<img
									src={item.image}
									alt="slider-image"
									className="h-60 w-full object-cover"
								/>

								<div className="px-7 py-5">
									<p className="text-sm font-bold text-primary">Featured Integration</p>

									<div className="pt-3" />

									<h2 className="text-2xl font-bold">{item.title}</h2>

									<div className="pt-4" />

									<button
										onClick={item.onClick}
										className="rounded-full bg-black-7 px-6 py-2 font-bold text-white dark:bg-[#1f1d2b]"
									>
										Get Started
									</button>
								</div>
							</div>
						</div>
					))}
				</Carousel>
			</div>

			<div
				className={cn('w-[calc(100dvw-2.5rem)] md:w-[calc(100dvw-3.5rem)] min-[1100px]:w-[calc(100dvw-21.5rem)] min-[1161px]:hidden', {
					'min-[1100px]:w-[calc(100dvw-9.5rem)]': isCollapsed,
				})}
			>
				<Carousel
					dots
					draggable
				>
					{sliderData.map((item: SliderData, index: number) => (
						<img
							src={item.image}
							alt="slider-image"
							className="h-auto w-full rounded-lg object-cover md:max-h-96"
							key={index}
						/>
					))}
				</Carousel>
			</div>
		</Fragment>
	);
};

export default RuthConnectSlider;
