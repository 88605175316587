import { useQuery } from "@tanstack/react-query";
import { Integration } from "../components/ruth-connect/ruth-connect-types";
import axios from "../config/axios";

export const useConnectedAppsQuery = () => {
	return useQuery<Integration[]>({
		queryKey: ['connected-apps'],
		queryFn: async () => {
			const { data } = await axios.get('/ruth-connect/integrations', { withCredentials: true });
			return data.results;
		},
	})
};