import { useQuery } from '@tanstack/react-query';
import { Card, Divider, Popover } from 'antd';
import { useState } from 'react';
import { CgSelect } from 'react-icons/cg';
import { GrCircleInformation, GrFormCheckmark } from 'react-icons/gr';
import { Link, useNavigate } from 'react-router-dom';
import CreateWorkspaceModal from '../components/layouts/create-workspace-modal.tsx';
import Loader from '../components/Loader.tsx';
import axios from '../config/axios';
import { cn } from '../config/cn';
import queryClient from '../config/query.ts';
import { useAuthContext } from '../context/auth.context';
import { useSidebarContext } from '../context/sidebar.context';
import { useBalanceQuery } from '../hooks/use-balance-query';
import { formatNumber } from '../utils/helpers';
import { mainFeatures, settings } from './menu-items';
import RenderMenu from './render-menu';

export type Workspace = {
	workspace_id: string;
	name: string;
	is_default: boolean;
	created_at: Date;
	updated_at: Date;
};

const SidebarBottom = () => {
	const navigate = useNavigate();
	const { isCollapsed } = useSidebarContext();
	const { setUser, setIsAuthenticated } = useAuthContext();
	const [createWorkspaceModalOpen, setCreateWorkspaceModalOpen] = useState(false);
	const [popoverOpen, setPopoverOpen] = useState(false);

	// get the default workspace from local storage
	let selectedWorkspace: Workspace | null = null;
	try {
		const storedWorkspace = localStorage.getItem('default_workspace');
		if (storedWorkspace) {
			const parsed = JSON.parse(storedWorkspace);
			// Validate the parsed data has required Workspace properties
			if (parsed && typeof parsed === 'object' && 'workspace_id' in parsed) {
				selectedWorkspace = parsed;
			}
		}
	} catch (error) {
		console.error('Failed to parse workspace from localStorage:', error);
	}

	// get users balance breakdown
	const { data: balance, isLoading: balanceLoading } = useBalanceQuery();

	// logout
	const handleLogout = () => {
		setIsAuthenticated(false);
		setUser(null);
		navigate('/login');
		window.localStorage.clear();
		queryClient.clear();
	};

	// get workspaces
	const { data: workspaces, isLoading: workspacesLoading } = useQuery<Workspace[]>({
		queryKey: ['workspaces'],
		queryFn: async () => {
			const { data } = await axios.get('/workspaces', { withCredentials: true });

			// if there is no default workspace, set the first workspace as the default
			if (!selectedWorkspace) {
				const firstWorkspace = data.results[0];
				localStorage.setItem('default_workspace', JSON.stringify(firstWorkspace));
			}

			return data.results;
		},
	});

	// content for workspace popover
	const workspacePopoverContent = (
		<div className="flex w-60 flex-col">
			{workspacesLoading && (
				<div className="flex h-20 items-center justify-center">
					<Loader />
				</div>
			)}

			{workspaces &&
				workspaces.map((workspace, index) => (
					<div
						key={index}
						className="flex items-center gap-2 rounded p-2 hover:bg-gray-200 dark:hover:bg-gray-800"
						role="button"
						onClick={() => {
							if (selectedWorkspace?.workspace_id === workspace.workspace_id) return;

							// save the workspace in local storage as default
							localStorage.setItem('default_workspace', JSON.stringify(workspace));

							// reload the page with current path but without any query parameters
							window.location.href = window.location.origin + window.location.pathname;
						}}
					>
						<div className="rounded bg-primary px-2 text-white">{workspace.name.charAt(0)}</div>
						<div
							className="max-w-44 truncate text-base font-semibold"
							title={workspace.name}
						>
							{workspace.name}
						</div>

						{selectedWorkspace?.workspace_id === workspace.workspace_id && (
							<div>
								<GrFormCheckmark size={22} />
							</div>
						)}
					</div>
				))}

			<Divider className="my-1.5" />

			<div
				className="flex items-center gap-2 rounded p-2 hover:bg-gray-200 dark:hover:bg-gray-800"
				role="button"
				onClick={() => {
					setCreateWorkspaceModalOpen(true);
					setPopoverOpen(false);
				}}
			>
				<div className="rounded bg-primary px-2 text-white">+</div>
				<div className="max-w-44 truncate text-base font-semibold">Create Workspace</div>
			</div>

			<Link to="/workspaces">
				<div className="flex items-center gap-2 rounded p-2 hover:bg-gray-200 dark:hover:bg-gray-800">
					<div className="rounded bg-primary px-2 text-white">A</div>
					<div className="max-w-44 truncate text-base font-semibold text-black-7 dark:text-white">All Workspaces</div>
				</div>
			</Link>
		</div>
	);

	return (
		<div
			className={cn(
				'scrollbar-hidden dark:bg-black flex h-[calc(100dvh-4.6rem)] flex-col overflow-x-auto min-[1100px]:h-[calc(100dvh-6.1rem)] dark:text-white',
				{
					'w-24': isCollapsed,
					'w-72': !isCollapsed,
				},
			)}
		>
			<div className="px-5 pt-3">
				{!isCollapsed && (
					<div className="rounded-lg border p-2 text-base font-semibold text-gray-600 dark:border-[#FFFFFF0D] dark:bg-[#1F1D2B] dark:text-white">
						Current Balance: {balanceLoading ? '...' : `$${formatNumber(balance?.balance ?? 0)}`}
					</div>
				)}

				<div className="pt-2" />

				{!isCollapsed && selectedWorkspace && (
					<Popover
						content={workspacePopoverContent}
						trigger="click"
						open={popoverOpen}
						placement="bottomLeft"
						arrow={false}
						className="cursor-pointer"
						styles={{
							body: {
								padding: 8,
							},
						}}
						onOpenChange={setPopoverOpen}
					>
						<div className="rounded-lg border p-2 text-base font-semibold text-gray-600 dark:border-[#FFFFFF0D] dark:bg-[#1F1D2B] dark:text-white">
							<div className="flex items-center justify-between gap-2">
								<div className="flex items-center gap-2">
									<div className="rounded bg-primary px-2 text-white">{selectedWorkspace.name.charAt(0)}</div>
									<div className="max-w-40 truncate">{selectedWorkspace.name}</div>
								</div>
								<div>
									<CgSelect size={20} />
								</div>
							</div>
						</div>
					</Popover>
				)}

				<div className="pt-2" />

				<Link
					to={'/dashboard'}
					title="Dashboard"
				>
					<div
						className={cn('flex items-center gap-2.5 rounded-lg px-2.5 py-2 transition-all duration-300', {
							'justify-center': isCollapsed,
							'bg-[#B18EED1A] text-link-active': location.pathname.includes('/dashboard'),
							'text-black-7 dark:text-white': !location.pathname.includes('/dashboard'),
						})}
					>
						<img
							src="/images/layout/dashboard-active-icon.svg"
							className={cn('size-6', {
								hidden: !location.pathname.includes('/dashboard'),
							})}
							alt="active-icon"
						/>
						<img
							src="/images/layout/dashboard-inactive-icon.svg"
							className={cn('size-6', {
								hidden: location.pathname.includes('/dashboard'),
							})}
							alt="inactive-icon"
						/>
						{!isCollapsed && <div className={cn('truncate text-base font-semibold')}>Dashboard</div>}
					</div>
				</Link>

				<div className="pt-3" />

				{!isCollapsed && <div className="pb-2 pl-3 text-base font-bold text-gray-500">Main feature</div>}

				<RenderMenu items={mainFeatures} />
			</div>

			<div className="px-5 py-3">
				{!isCollapsed && <div className="pb-2 pl-3 text-base font-bold text-gray-500">Settings</div>}

				<RenderMenu items={settings} />
			</div>

			<div className="mt-auto px-5">
				<Card
					styles={{ body: { padding: 8 } }}
					className="bg-inherit"
				>
					<div
						className={cn('flex items-center justify-between text-sm', {
							hidden: isCollapsed,
						})}
					>
						{balanceLoading ? (
							<div className="font-semibold">Retrieving...</div>
						) : balance ? (
							<div className="flex items-center gap-1">
								<div className="font-semibold">
									{balance.total_minutes >= 1000
										? `${(balance.total_minutes / 1000).toFixed(1)}k`
										: balance.total_minutes.toFixed(2)}{' '}
									MIN Remain
								</div>
								<Popover
									arrow={false}
									content={
										<div>
											<div>
												<strong>Total Minutes:</strong> {formatNumber(balance.total_minutes.toFixed(2))}
											</div>
											<div>
												<strong>Balance:</strong> ${formatNumber(balance.balance.toFixed(2))}
											</div>
											<div>
												<strong>Rate per Minute:</strong> ${formatNumber(balance.rate_per_minute.toFixed(2))}
											</div>
										</div>
									}
									title="Balance Breakdown"
								>
									<GrCircleInformation role="button" />
								</Popover>
							</div>
						) : null}
					</div>
				</Card>

				<div className="pt-2" />

				<div
					className={cn('flex items-center gap-2.5 rounded-lg px-2.5 py-2 pb-6 transition-all duration-300', {
						'justify-center': isCollapsed,
					})}
					role="button"
					onClick={handleLogout}
					title="Logout"
				>
					<img
						src="/images/layout/logout-icon.svg"
						className="size-6"
						alt="logout-icon"
					/>
					{!isCollapsed && <div className={cn('truncate text-base font-semibold text-black-7 dark:text-white')}>Logout</div>}
				</div>
			</div>

			<CreateWorkspaceModal
				open={createWorkspaceModalOpen}
				close={() => setCreateWorkspaceModalOpen(false)}
			/>
		</div>
	);
};

export default SidebarBottom;
