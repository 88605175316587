import { useQuery } from '@tanstack/react-query';
import axios from '../config/axios';

type Balance = {
	total_minutes: number;
	balance: number;
	rate_per_minute: number;
};

export const useBalanceQuery = () => {
	return useQuery<Balance>({
		queryKey: ['balances'],
		queryFn: async () => {
			const { data } = await axios.get('/billing/balance-breakdown', { withCredentials: true });
			return data.results;
		},
	});
};
