import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { User } from '../types/user.types';

interface ContextProps {
	user: User | null;
	setUser: Dispatch<SetStateAction<User | null>>;
	isAuthenticated: boolean;
	setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
}

export const AuthContext = createContext<ContextProps>({
	user: null,
	setUser: () => null,
	isAuthenticated: false,
	setIsAuthenticated: () => false,
});

export const useAuthContext = () => {
	const context = useContext(AuthContext);

	if (!context) {
		throw new Error('useAuth must be used within an AuthProvider');
	}

	const { user, setUser, isAuthenticated, setIsAuthenticated } = context;

	return { user, setUser, isAuthenticated, setIsAuthenticated } as const;
};
