import { FC, PropsWithChildren, useState } from 'react';
import { SidebarContext } from './sidebar.context';

export const SidebarProvider: FC<PropsWithChildren> = ({ children }) => {
	const [isCollapsed, setIsCollapsed] = useState(false);

	return (
		<SidebarContext.Provider
			value={{
				isCollapsed,
				setIsCollapsed,
			}}
		>
			{children}
		</SidebarContext.Provider>
	);
};
