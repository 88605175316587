import { useQuery } from '@tanstack/react-query';
import axios from '../config/axios';

export type CallReport = {
	total_calls_made: number;
	total_incoming_calls: number;
	total_outgoing_calls: number;
	web_calls: number;
	voip_calls: number;
	total_seconds: number;
};

export type ChatReport = {
	total_chat_sessions: number;
	total_messages_sent: number;
	total_messages_received: number;
	total_messages: number;
};

type CallChartData = {
	date: string;
	'Incoming Calls': number;
	'Outgoing Calls': number;
	'Total Calls': number;
};

type ChatChartData = {
	date: string;
	'Messages Sent': number;
	'Messages Received': number;
	'Total Messages': number;
};

export type CallSummary = CallReport & {
	date: string;
};

export type ChatSummary = ChatReport & {
	date: string;
};

export const useCallReportQuery = (dateRange: [string, string], enabled: boolean = true) => {
	return useQuery<CallReport>({
		queryKey: ['call-report', dateRange],
		queryFn: async () => {
			const { data } = await axios.get('/reports/call-report', {
				withCredentials: true,
				params: {
					start: dateRange[0],
					end: dateRange[1],
				},
			});
			return data.results;
		},
		enabled,
	});
};

export const useCallSummaryQuery = () => {
	return useQuery<CallChartData[]>({
		queryKey: ['call-summary'],
		queryFn: async () => {
			const { data } = await axios.get('/reports/call-summary', {
				withCredentials: true,
			});
			return data.results.map((result: CallSummary) => ({
				date: result.date,
				'Incoming Calls': result.total_incoming_calls,
				'Outgoing Calls': result.total_outgoing_calls,
				'Total Calls': result.total_calls_made,
			}));
		},
	});
};

export const useChatReportQuery = (dateRange: [string, string], enabled: boolean = true) => {
	return useQuery<ChatReport>({
		queryKey: ['chat-report', dateRange],
		queryFn: async () => {
			const { data } = await axios.get('/reports/chat-report', {
				withCredentials: true,
				params: {
					start: dateRange[0],
					end: dateRange[1],
				},
			});
			return data.results;
		},
		enabled,
	});
};

export const useChatSummaryQuery = () => {
	return useQuery<ChatChartData[]>({
		queryKey: ['chat-summary'],
		queryFn: async () => {
			const { data } = await axios.get('/reports/chat-summary', {
				withCredentials: true,
			});
			return data.results.map((result: ChatSummary) => ({
				date: result.date,
				'Messages Sent': result.total_messages_sent,
				'Messages Received': result.total_messages_received,
				'Total Messages': result.total_messages,
			}));
		},
	});
};
