import { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../context/auth.context';
import { useThemeStore } from '../store/theme';

const AuthLayout: FC<PropsWithChildren> = ({ children }) => {
	const { isAuthenticated } = useAuthContext();
	const { is_dark_mode } = useThemeStore();

	// if user is already logged in redirect to home page
	if (isAuthenticated) {
		return (
			<Navigate
				to="/dashboard"
				replace
			/>
		);
	}

	return (
		<section className="dark:bg-dark-bg">
			<div className="container h-dvh px-5 py-10">
				<div className="grid h-full grid-cols-1 items-center xl:grid-cols-2">
					<div className="hidden h-full xl:block">
						<div
							style={{
								background: 'linear-gradient(134.29deg, #6E5DED 7.18%, #B18EED 46.31%, #CDB6D1 91.85%)',
							}}
							className="relative h-full overflow-hidden rounded-xl"
						>
							<h1 className="max-w-lg pl-14 pt-12 text-4xl font-bold text-white">Humanizing Conversational Al®</h1>

							<img
								src={is_dark_mode ? '/images/auth/auth-left-image-dark.svg' : '/images/auth/auth-left-image.svg'}
								alt="auth-left-image"
								className="absolute right-0 top-48 2xl:top-36"
							/>
						</div>
					</div>
					<div className="lg:px-28">{children}</div>
				</div>
			</div>
		</section>
	);
};

export default AuthLayout;
