import { FC, ReactNode, useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { cn } from '../../config/cn';

interface FilterSectionProps {
	title: string;
	children: ReactNode;
}

const FilterSection: FC<FilterSectionProps> = ({ title, children }) => {
	const [isExpanded, setIsExpanded] = useState(true);

	return (
		<div className={cn('mb-4', { 'mb-2': !isExpanded })}>
			<button
				onClick={() => setIsExpanded(!isExpanded)}
				className="group flex w-full items-center justify-between"
			>
				<div className="text-lg font-semibold text-gray-900 dark:text-white">{title}</div>
				{isExpanded ? <BsChevronUp className="text-gray-900 dark:text-white" /> : <BsChevronDown className="text-gray-900 dark:text-white" />}
			</button>

			<div className="pt-3" />

			{isExpanded && <div>{children}</div>}
		</div>
	);
};

export default FilterSection;
