import { Card, Skeleton } from 'antd';
import dayjs from 'dayjs';
import { FC, Fragment, useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { cn } from '../../config/cn';
import { CallReport, useCallReportQuery, useCallSummaryQuery } from '../../hooks/use-analytics-query';
import { useThemeStore } from '../../store/theme';
import { colors } from '../../theme/colors';
import { compareCallPercentages, formatSeconds } from '../../utils/helpers';
import SentimentChart from './sentiment-chart';

type DashboardItem = {
	id: string;
	imgLight: string;
	imgDark: string;
	altText: string;
	title: string;
	up: boolean;
	count: string | number | undefined;
	percentage: number | undefined;
};

type CallsAnalyticsProps = {
	dateRange: [string, string];
};

const CallsAnalytics: FC<CallsAnalyticsProps> = (props) => {
	const { is_dark_mode } = useThemeStore();
	const isCurrentMonth =
		props.dateRange[0] === dayjs().startOf('month').format('YYYY-MM-DD') && props.dateRange[1] === dayjs().format('YYYY-MM-DD');

	const [dashboardItems, setDashboardItems] = useState<DashboardItem[]>(() => [
		{
			id: 'total-calls-made',
			imgLight: '/images/dashboard/total-calls-made-light.svg',
			imgDark: '/images/dashboard/total-calls-made-dark.svg',
			altText: 'total-calls-made',
			title: 'Total Calls Made',
			up: false,
			count: 0,
			percentage: 0,
		},
		{
			id: 'total-calls-received',
			imgLight: '/images/dashboard/total-calls-received-light.svg',
			imgDark: '/images/dashboard/total-calls-received-dark.svg',
			altText: 'total-calls-received',
			title: 'Total Calls Received',
			up: false,
			count: 0,
			percentage: 0,
		},
		{
			id: 'total-calls-outgoing',
			imgLight: '/images/dashboard/total-calls-outgoing-light.svg',
			imgDark: '/images/dashboard/total-calls-outgoing-dark.svg',
			altText: 'total-calls-outgoing',
			title: 'Total Outgoing Calls',
			up: false,
			count: 0,
			percentage: 0,
		},
		{
			id: 'call-volumes',
			imgLight: '/images/dashboard/call-volumes-light.svg',
			imgDark: '/images/dashboard/call-volumes-dark.svg',
			altText: 'call-volumes',
			title: 'Call Volumes',
			up: false,
			count: 0,
			percentage: 0,
		},
	]);
	const { width } = useWindowSize();

	// get call analytics data
	const { data: callReportData, isLoading: callReportLoading } = useCallReportQuery(props.dateRange);

	// get last month data, only if filter is current month
	const { data: lastMonthData } = useCallReportQuery(
		[
			dayjs(props.dateRange[0]).subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
			dayjs(props.dateRange[1]).subtract(1, 'month').format('YYYY-MM-DD'),
		],
		isCurrentMonth,
	);

	// update dashboard items
	useEffect(() => {
		if (!callReportData) return;

		const keys = ['total_calls_made', 'total_incoming_calls', 'total_outgoing_calls', 'total_seconds'];
		const comparisonResults = isCurrentMonth && lastMonthData ? compareCallPercentages(callReportData, lastMonthData) : null;

		setDashboardItems((prev) =>
			prev.map((item, index) => {
				const key = keys[index];
				return {
					...item,
					count: key === 'total_seconds' ? formatSeconds(callReportData[key as keyof CallReport]) : callReportData[key as keyof CallReport],
					percentage: comparisonResults?.[key]?.percentageChange ?? 0,
					up: comparisonResults?.[key]?.direction === 'up' ? true : false,
				};
			}),
		);
	}, [callReportData, lastMonthData, isCurrentMonth]);

	// get call summary data
	const { data: callSummaryData, isLoading: callSummaryLoading } = useCallSummaryQuery();

	// set last 10 days data
	const [last10DaysCallCount, setLast10DaysCallCount] = useState<number>(0);

	useEffect(() => {
		if (!callSummaryData) return;

		const last10DaysData = callSummaryData;
		const totalCallsMade = last10DaysData.reduce((acc, curr) => acc + curr['Total Calls'], 0);

		setLast10DaysCallCount(totalCallsMade);
	}, [callSummaryData]);

	return (
		<Fragment>
			<div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:flex">
				{dashboardItems.map((item, index) => (
					<Fragment key={index}>
						<div className="flex gap-3.5">
							<img
								src={is_dark_mode ? item.imgDark : item.imgLight}
								alt={item.altText}
								className="size-11"
							/>
							<div>
								<div className="text-base font-medium text-black-3 dark:text-[#8a8990]">{item.title}</div>

								<div className="pt-3" />

								{callReportLoading ? (
									<Skeleton.Button
										active
										className="!w-52 lg:!w-36 2xl:!w-52"
									/>
								) : (
									<div className="text-2xl font-semibold leading-none text-black-7 dark:text-white"> {item.count}</div>
								)}

								<div className="pt-3" />

								{callReportLoading && isCurrentMonth ? (
									<Skeleton
										active
										paragraph={false}
										className="!w-56 lg:!w-36 2xl:!w-56"
									/>
								) : (
									isCurrentMonth && (
										<div className="flex flex-row flex-wrap gap-2">
											<div className="flex items-center gap-2">
												<img
													src={`/images/dashboard/graph-${item.up ? 'up' : 'down'}.svg`}
													alt={`graph-${item.up ? 'up' : 'down'}`}
												/>

												<div
													className={cn('text-sm font-bold', {
														'text-green-600': item.up,
														'text-red-600': !item.up,
													})}
												>
													{item.up ? '+' : '-'}
													{item.percentage}%
												</div>
											</div>

											<div className="text-sm font-medium text-black-3 dark:text-[#8a8990]">(Compared to last month)</div>
										</div>
									)
								)}
							</div>
						</div>

						{index !== dashboardItems.length - 1 && <div className="hidden border lg:block dark:border-[#2d2b38]" />}
					</Fragment>
				))}
			</div>

			<div className="pt-9" />

			<Card
				className="h-full"
				styles={{ body: { padding: 15 } }}
			>
				<div className="flex items-center justify-between gap-2">
					<h2 className="text-xl font-semibold text-black-7 dark:text-white">Last 10 Days Calls</h2>
				</div>

				<div className="pt-4" />

				<div>
					<div className="text-base font-medium text-black-3 dark:text-[#8a8990]">Total Calls</div>

					<div className="pt-3" />

					{callSummaryLoading ? (
						<Skeleton.Button
							active
							className="!w-52 lg:!w-36 2xl:!w-52"
						/>
					) : (
						<div className="text-2xl font-bold leading-none text-black-7 dark:text-white">{last10DaysCallCount}</div>
					)}
				</div>

				<div className="pt-6" />

				<div>
					{callSummaryLoading ? (
						<div className="flex h-60 items-center justify-center lg:h-96">
							<img
								src={is_dark_mode ? '/images/dashboard/chart-loading-dark.svg' : '/images/dashboard/chart-loading-light.svg'}
								alt="chart-loading"
								className="size-10"
							/>
						</div>
					) : (
						<ResponsiveContainer
							width="100%"
							{...(width > 1000
								? { aspect: 4 }
								: {
										height: 220,
									})}
						>
							<AreaChart
								data={callSummaryData}
								margin={{
									top: 5,
									right: 30,
								}}
							>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="date" />
								<YAxis />
								<Tooltip
									contentStyle={{
										color: is_dark_mode ? 'white' : 'black',
										backgroundColor: is_dark_mode ? '#161422' : 'white',
										border: is_dark_mode ? '1px solid #2d2b38' : '1px solid #f0f0f0',
										borderRadius: 8,
									}}
								/>
								<Legend />
								<Area
									type="bump"
									dataKey="Incoming Calls"
									stroke="#6e5ded"
									fill={colors.primary}
									activeDot={{ r: 8 }}
								/>
								<Area
									type="bump"
									dataKey="Outgoing Calls"
									stroke="#b18eed"
								/>
							</AreaChart>
						</ResponsiveContainer>
					)}
				</div>
			</Card>

			<div className="pt-6" />

			<SentimentChart />

			<div className="pb-8" />
		</Fragment>
	);
};

export default CallsAnalytics;
