import { AxiosError } from 'axios';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import Loader from '../components/Loader';
import axios from '../config/axios';
import { User } from '../types/user.types';
import { AuthContext } from './auth.context';

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
	const [user, setUser] = useState<User | null>(null);
	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const maintenanceMode = false;

	useEffect(() => {
		(async () => {
			try {
				const { data } = await axios.get('/users/authenticate', { withCredentials: true });
				setIsAuthenticated(true);
				setUser(data.results);
			} catch (error) {
				if (error instanceof AxiosError && error.response) {
					setIsAuthenticated(false);
					setUser(null);
				}
				// clear local storage
				window.localStorage.clear();
			} finally {
				setLoading(false);
			}
		})();
	}, []);

	return loading ? (
		<div className="flex h-dvh items-center justify-center dark:bg-dark-bg">
			<Loader />
		</div>
	) : maintenanceMode ? (
		<div className="flex h-dvh items-center justify-center dark:bg-dark-bg">
			<div className="flex flex-col items-center justify-center gap-4 px-4">
				<img
					src="/images/misc/maintenance_mode.png"
					alt="maintenance_mode"
					className="size-20 dark:invert"
				/>

				<h1 className="text-center text-2xl font-bold md:text-4xl dark:text-white">System Upgrade in Progress.</h1>

				<h2 className="text-center text-base font-medium md:text-xl dark:text-white">
					Our team of engineers is currently conducting scheduled maintenance.
				</h2>
			</div>
		</div>
	) : (
		<AuthContext.Provider
			value={{
				user,
				setUser,
				isAuthenticated,
				setIsAuthenticated,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
