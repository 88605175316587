import { CustomToolFormState, SchemaProperty } from '../../types/custom-tool.types';

export const getTitleSubtitle = (step: number) => {
	switch (step) {
		case 2:
			return {
				title: 'Build your Schema',
				subtitle: 'Define your schema which the LLM should generate.',
			};
		case 3:
			return {
				title: 'What is this tool for?',
				subtitle: 'Add Details to your tool which will be used by the LLM to understand and call the tool.',
			};
		case 4:
			return {
				title: 'What messages should be shown to the user as the tool is running?',
				subtitle: 'Configure the messages that should be shown to the user as the tool is running.',
			};
		default:
			return {
				title: 'Choose a Provider.',
				subtitle: 'Create a custom tool to connect to your data.',
			};
	}
};

// Helper function to format object properties
const formatObjectProperties = (props: Record<string, SchemaProperty>): Record<string, unknown> => {
	const result: Record<string, unknown> = {};

	Object.values(props).forEach((prop) => {
		if (!prop.key.trim()) return;

		switch (prop.type) {
			case 'string':
				result[prop.key] = {
					type: 'string',
					description: prop.value as string,
				};
				break;
			case 'number':
				result[prop.key] = {
					type: 'number',
					description: String(prop.value),
				};
				break;
			case 'boolean':
				result[prop.key] = {
					type: 'boolean',
					description: String(prop.value),
				};
				break;
			case 'array':
				result[prop.key] = {
					type: 'string',
					description: `${prop.key} options`,
					enum: prop.value as string[],
				};
				break;
			case 'object':
				result[prop.key] = {
					type: 'object',
					description: `${prop.key} object`,
					properties: formatObjectProperties(prop.value as Record<string, SchemaProperty>),
				};
				break;
		}
	});

	return result;
};

// Format data for API
export const formatDataForApi = (formState: CustomToolFormState) => {
	// Format schema properties for function parameters
	const properties: Record<string, unknown> = {};
	const required: string[] = [...formState.requiredFields];

	formState.schemaProperties.forEach((prop) => {
		if (!prop.key.trim()) return;

		let propSchema: Record<string, unknown> = {};
		switch (prop.type) {
			case 'string':
				propSchema = {
					type: 'string',
					description: prop.value as string,
				};
				break;
			case 'number':
				propSchema = {
					type: 'number',
					description: String(prop.value),
				};
				break;
			case 'boolean':
				propSchema = {
					type: 'boolean',
					description: String(prop.value),
				};
				break;
			case 'array':
				propSchema = {
					type: 'array',
					description: `${prop.key} options`,
					enum: prop.value as string[],
				};
				break;
			case 'object':
				propSchema = {
					type: 'object',
					description: `${prop.key} object`,
					properties: formatObjectProperties(prop.value as Record<string, SchemaProperty>),
				};
				break;
		}

		properties[prop.key] = propSchema;
	});

	// Format headers as object
	const headersObj: Record<string, string> = {};
	formState.headers.forEach((header) => {
		if (header.key.trim()) {
			headersObj[header.key] = header.value;
		}
	});

	return {
		type: 'function',
		functionId: formState.toolName,
		function: {
			name: formState.toolName,
			description: formState.toolDescription,
			serverUrl: formState.serverUrl,
			timeoutSeconds: formState.timeoutSeconds,
			headers: headersObj,
			parameters: {
				type: 'object',
				properties,
				required,
			},
			messages: {
				start: formState.startMessage,
				delayed: formState.delayedMessage,
				success: formState.successMessage,
				error: formState.errorMessage,
			},
		},
	};
};
