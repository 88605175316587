import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Button, Input, Modal } from 'antd';
import { FC } from 'react';
import toast from 'react-hot-toast';
import { HiSpeakerWave } from 'react-icons/hi2';
import { MdOutlineClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import axios from '../../config/axios';
import { handleAPIError } from '../../utils/helpers';
import { Template } from '../../views/assistant-templates';

type Props = {
	open: boolean;
	close: () => void;
	template: Template;
};

const TemplateDescriptionModal: FC<Props> = ({ open, close, template }) => {
	const navigate = useNavigate();

	// create assistant mutation
	const { mutate: createAssistant, isPending } = useMutation({
		mutationFn: async (template: Template) => {
			const payload = {
				template: template.template_id,
				name: template.name,
				voice: template.voice.voice_id,
				prompt: template.prompt,
				model: template.model,
				assistant_type: template.assistant_type,
				timezone: template.timezone,
				avatar: template.avatar,
				who_speaks_first: template.who_speaks_first,
				begin_message: template.begin_message,
			};

			const { data } = await axios.post('/ai-assistants', payload, { withCredentials: true });
			return data.results;
		},
		onSuccess: (data) => {
			toast.success('Assistant created successfully');
			close();
			navigate(`/ai-assistants?assistant=${data.assistant_id}`);
		},
		onError: handleAPIError,
	});

	return (
		<Modal
			open={open}
			footer={null}
			onCancel={close}
			centered
			destroyOnClose
			closeIcon={null}
			closable={false}
			className="relative"
			width={800}
		>
			<div className="col-span-3 flex flex-wrap items-center justify-between gap-6 p-0">
				<div className="relative flex items-center gap-3">
					<div
						className="absolute bottom-0 rounded-full bg-primary p-1 text-white outline outline-white"
						role="button"
						onClick={() => {
							// play the audio
							if (template.voice.demo_audio_url) {
								const audio = new Audio(template.voice.demo_audio_url);
								audio.play();
							} else {
								toast.error('No audio available');
							}
						}}
					>
						<HiSpeakerWave size={10} />
					</div>
					<img
						src={template.avatar}
						alt={template.name}
						className="size-14 rounded-full"
					/>
					<div className="flex flex-col">
						<div className="text-lg font-semibold capitalize">{template.name}</div>
						{template.industry && <div className="text-sm font-semibold text-gray-500">{template.industry.name}</div>}
					</div>
				</div>
				<div className="flex items-center gap-2">
					<Button
						type="primary"
						size="large"
						onClick={() => {
							createAssistant(template);
						}}
						icon={<PlusOutlined size={20} />}
						loading={isPending}
						disabled={isPending}
					>
						Create Assistant
					</Button>
				</div>
			</div>

			<div className="pt-6" />

			<div className="grid grid-cols-3 rounded-2xl border border-gray-300 dark:border-[#2d2b38]">
				<div className="col-span-3 flex max-h-96 flex-col gap-2 overflow-y-auto p-6 pb-0 md:col-span-2 md:pb-6">
					<span className="text-base font-medium">Prompt</span>
					<Input.TextArea
						rows={14}
						value={template.prompt}
						readOnly
						variant="borderless"
						styles={{
							textarea: {
								scrollbarWidth: 'none',
								padding: 0,
							},
						}}
						style={{ resize: 'none' }}
					/>
				</div>
				<div className="col-span-3 md:col-span-1">
					<ul className="flex h-full flex-col border-gray-300 md:border-l dark:border-[#2d2b38]">
						<li className="flex flex-col gap-1 border-b border-gray-200 p-4 dark:border-[#2d2b38]">
							<span className="text-base font-medium">AI Model</span>
							<span className="text-gray-400">{template.model}</span>
						</li>
						<li className="flex flex-col gap-2 border-b border-gray-200 p-4 dark:border-[#2d2b38]">
							<span className="text-base font-medium">Voice Synthesizer</span>
							<div className="flex items-center gap-2">
								<img
									src={template.voice.avatar}
									alt={template.voice.name}
									className="size-6 rounded-full"
								/>
								<span className="font-medium text-gray-400">{template.voice.name}</span>
							</div>
						</li>
						<li className="flex flex-col gap-1 border-b border-gray-200 p-4 dark:border-[#2d2b38]">
							<span className="text-base font-medium">Timezone</span>
							<span className="text-gray-400">{template.timezone}</span>
						</li>
						<li className="flex flex-col gap-1 p-4">
							<span className="text-base font-medium">Assistant Type</span>
							<span className="text-gray-400">{template.assistant_type === 'single_prompt' ? 'Single Prompt' : 'Multi Prompt'}</span>
						</li>
					</ul>
				</div>
			</div>

			{/* close icon */}
			<div className="absolute -right-2 -top-3 flex items-center justify-center rounded-full border bg-white p-1 shadow-lg dark:border-[#1f1f1f] dark:bg-dark-bg">
				<button onClick={close}>
					<MdOutlineClose
						size={16}
						color="red"
					/>
				</button>
			</div>
		</Modal>
	);
};

export default TemplateDescriptionModal;
