import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Loader from '../components/Loader';
import axios from '../config/axios.ts';
import { useAuthContext } from '../context/auth.context';

const Redirect = () => {
	const location = useLocation();
	const { setUser, setIsAuthenticated } = useAuthContext();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		(async () => {
			const values = queryString.parse(location.search);
			const code = values.code ? values.code : null;
			const { data } = await axios.post(
				'/users/auth-via-providers',
				{
					auth_token: code,
					provider: 'google',
				},
				{ withCredentials: false },
			);
			window.localStorage.setItem('device_id', data.access_token);
			setIsAuthenticated(true);
			setUser(data.user);
			setLoading(false);
		})();
	}, [location.search, setIsAuthenticated, setUser]);

	return loading ? (
		<div className="flex h-dvh items-center justify-center">
			<Loader />
		</div>
	) : (
		<Navigate
			to="/dashboard"
			replace
		/>
	);
};

export default Redirect;
