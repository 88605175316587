import { useMutation } from '@tanstack/react-query';
import { Button, Form, Input, Modal } from 'antd';
import toast from 'react-hot-toast';
import axios from '../../config/axios';
import { handleAPIError } from '../../utils/helpers';

type Props = {
	open: boolean;
	close: () => void;
};

type FieldType = {
	password: string;
	confirm_password: string;
};

const SwitchToEmailModal = (props: Props) => {
	// switch to email mutation
	const { mutate: switchToEmail, isPending: isSwitchingToEmail } = useMutation({
		mutationFn: async (values: FieldType) => {
			await axios.post('/users/switch-to-email-auth', values, { withCredentials: true });
			return true;
		},
		onSuccess: () => {
			toast.success('Authentication method switched to email');
			window.location.reload();
		},
		onError: handleAPIError,
	});

	return (
		<Modal
			open={props.open}
			onCancel={props.close}
			footer={null}
			centered
			title="Switch to Email"
			destroyOnClose
		>
			<Form
				layout="vertical"
				requiredMark={false}
				onFinish={(values) => switchToEmail(values)}
			>
				<Form.Item<FieldType>
					label={<div className="text-base font-medium">Password</div>}
					name="password"
					rules={[{ required: true, message: 'Please input your password!' }]}
					hasFeedback
				>
					<Input.Password
						size="large"
						placeholder="Enter your password"
					/>
				</Form.Item>

				<Form.Item<FieldType>
					label={<div className="text-base font-medium">Confirm Password</div>}
					name="confirm_password"
					rules={[
						{
							required: true,
							message: 'Please confirm your password!',
						},
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || getFieldValue('password') === value) {
									return Promise.resolve();
								}
								return Promise.reject(new Error('The new password that you entered do not match!'));
							},
						}),
					]}
					dependencies={['password']}
					hasFeedback
				>
					<Input.Password
						size="large"
						placeholder="Confirm your password"
					/>
				</Form.Item>

				<Form.Item className="mb-0">
					<Button
						type="primary"
						htmlType="submit"
						block
						size="large"
						loading={isSwitchingToEmail}
						disabled={isSwitchingToEmail}
					>
						Switch to Email
					</Button>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default SwitchToEmailModal;
