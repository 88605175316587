import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { cn } from '../../config/cn';
import { useConnectedAppsQuery } from '../../hooks/use-connected-apps-query';
import CreateCustomToolDrawer from './create-custom-tool-drawer';
import RuthConnectCard from './ruth-connect-card';
import { App, Filter, apps, filters } from './ruth-connect-data';
import { Integration } from './ruth-connect-types';

const RuthConnectApps = () => {
	const [activeFilter, setActiveFilter] = useState('all');
	const [filteredApps, setFilteredApps] = useState<App[]>(() => apps);
	const [drawerOpen, setDrawerOpen] = useState(false);

	// get the apps that is connected by the user
	const { data: connectedApps } = useConnectedAppsQuery();

	return (
		<div>
			<div className={'flex flex-wrap items-center justify-between gap-4'}>
				<div className="flex items-center gap-3">
					<h2 className={'text-lg font-medium text-gray-700 dark:text-white'}>All Apps</h2>
					<div
						className="mt-1 cursor-pointer text-base font-medium text-primary"
						onClick={() => setDrawerOpen(true)}
					>
						Add Custom Tool
					</div>
				</div>
				<div className={'flex flex-wrap items-center gap-2 text-sm'}>
					{filters.map((filter: Filter, index: number) => (
						<div
							key={index}
							className={cn(`rounded-lg px-4 py-2 font-semibold transition-all`, {
								'bg-primary text-white': activeFilter === filter.value,
								'text-gray-600 dark:text-gray-300': activeFilter !== filter.value,
							})}
							role="button"
							onClick={() => {
								setActiveFilter(filter.value);
								if (filter.value === 'all') {
									setFilteredApps(apps);
								} else {
									setFilteredApps(apps.filter((app) => app.categories.includes(filter.value)));
								}
							}}
						>
							{filter.label}
						</div>
					))}
				</div>
			</div>

			<div className="pt-4" />

			<AnimatePresence>
				<div className="grid grid-cols-1 gap-4 pt-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
					{filteredApps.map((item: App, index: number) => (
						<motion.div
							key={`${item.vendor}-${item.title}`}
							layout
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.5 }}
						>
							<RuthConnectCard
								key={index}
								item={item}
								connectedApps={connectedApps as Integration[]}
							/>
						</motion.div>
					))}
				</div>
			</AnimatePresence>

			{/* create custom tool drawer */}
			<CreateCustomToolDrawer
				open={drawerOpen}
				close={() => setDrawerOpen(false)}
			/>
		</div>
	);
};

export default RuthConnectApps;
